import { Injectable, computed, signal } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class KeyboardService {
	// Event emitter for keyboard actions
	private keyboardEvent = new Subject<string>();

	// Signals for key states
	private anyPressed = signal(false);
	private wPressed = signal(false);
	private aPressed = signal(false);
	private sPressed = signal(false);
	private dPressed = signal(false);
	private spacePressed = signal(false);
	private upArrowPressed = signal(false);
	private downArrowPressed = signal(false);
	private leftArrowPressed = signal(false);
	private rightArrowPressed = signal(false);
	private escapePressed = signal(false);

	constructor() {
		this.initKeyboardEvents();
	}

	private initKeyboardEvents() {
		window.addEventListener("keydown", this.handleKeyDown.bind(this));
		window.addEventListener("keyup", this.handleKeyUp.bind(this));
	}

	private handleKeyDown(event: KeyboardEvent) {
		this.anyPressed.set(true);
		
		switch (event.key.toLowerCase()) {
			case "w":
				this.wPressed.set(true);
				break;
			case "a":
				this.aPressed.set(true);
				break;
			case "s":
				this.sPressed.set(true);
				break;
			case "d":
				this.dPressed.set(true);
				break;
			case " ":
				this.spacePressed.set(true);
				this.keyboardEvent.next("spacebar");
				break;
			case "escape":
				this.escapePressed.set(true);
				this.keyboardEvent.next("escape");
				break;
			case "n":
				this.keyboardEvent.next("KeyN");
				break;
			case "]":
				this.keyboardEvent.next("tab");
				break;
			case "arrowup":
				this.upArrowPressed.set(true);
				break;
			case "arrowdown":
				this.downArrowPressed.set(true);
				break;
			case "arrowleft":
				this.leftArrowPressed.set(true);
				this.keyboardEvent.next("key-left");
				break;
			case "arrowright":
				this.rightArrowPressed.set(true);
				this.keyboardEvent.next("key-right");
				break;
		}
	}

	private handleKeyUp(event: KeyboardEvent) {
		switch (event.key.toLowerCase()) {
			case "w":
				this.wPressed.set(false);
				break;
			case "a":
				this.aPressed.set(false);
				break;
			case "s":
				this.sPressed.set(false);
				break;
			case "d":
				this.dPressed.set(false);
				break;
			case " ":
				this.spacePressed.set(false);
				break;
			case "escape":
				this.escapePressed.set(false);
				break;
			case "arrowup":
				this.upArrowPressed.set(false);
				break;
			case "arrowdown":
				this.downArrowPressed.set(false);
				break;
			case "arrowleft":
				this.leftArrowPressed.set(false);
				break;
			case "arrowright":
				this.rightArrowPressed.set(false);
				break;
		}

		// Update anyPressed state
		if (!this.wPressed() && !this.aPressed() && !this.sPressed() && !this.dPressed()) {
			this.anyPressed.set(false);
		}
	}

	// Event stream for keyboard actions
	getKeyboardEvents() {
		return this.keyboardEvent.asObservable();
	}

	// Computed getters for key states
	get isAnyPressed() {
		return computed(() => this.anyPressed());
	}
	get isSpacePressed() {
		return computed(() => this.spacePressed());
	}
	get isEscapePressed() {
		return computed(() => this.escapePressed());
	}
	get isUpArrowPressed() {
		return computed(() => this.upArrowPressed());
	}
	get isDownArrowPressed() {
		return computed(() => this.downArrowPressed());
	}
	get isLeftArrowPressed() {
		return computed(() => this.leftArrowPressed());
	}
	get isRightArrowPressed() {
		return computed(() => this.rightArrowPressed());
	}
	get isWPressed() {
		return computed(() => this.wPressed());
	}
	get isAPressed() {
		return computed(() => this.aPressed());
	}
	get isSPressed() {
		return computed(() => this.sPressed());
	}
	get isDPressed() {
		return computed(() => this.dPressed());
	}

	// Cleanup
	destroy() {
		window.removeEventListener("keydown", this.handleKeyDown.bind(this));
		window.removeEventListener("keyup", this.handleKeyUp.bind(this));
		this.keyboardEvent.complete();
	}
}
