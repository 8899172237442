import { HttpClient } from "@angular/common/http";
import { Injectable, computed, signal } from "@angular/core";
import { environment } from "@env/environment";
import { baseAlbum, bitcoinAlbum, dogeAlbum, ethereumAlbum, longbeachAlbum, montereybayAlbum, polygonAlbum, solanaAlbum } from "../util/album";
import { defaultExhibit } from "../util/constants";
import { Album } from "../util/types";


export interface ExhibitSettings {
  name: string;
  description: string;
  // Add other settings properties
}

export interface ExhibitState {
  current: string;
  settings: ExhibitSettings | null;
	isLoading: boolean;
	symbol: string;
	album: Album;
	knobPosition: number;
	prices: { [key: string]: number };
	price: number | null;
	membershipPrice: number | null;
	admissionPrice: number | null;
	message: {
		trait_type: string;
		value: string;
	}[];
}

@Injectable({
	providedIn: 'root',
})
export class ExhibitService {
  // Core state
  private exhibitState = signal<ExhibitState>({
    current: defaultExhibit,
    settings: null,
		isLoading: false,
		symbol: this.getSymbol(defaultExhibit),
		album: this.getAlbum(defaultExhibit),
		knobPosition: this.getKnobPosition(defaultExhibit),
		prices: {},
		price: 0,
		membershipPrice: null,
		admissionPrice: null,
		message: [
			{
				trait_type: this.getSymbol(defaultExhibit),
				value: "",
			},
			{
				trait_type: "Established " + this.getAlbum(defaultExhibit).foundingDate,
				value: "",
			},
			{
				trait_type: "Headphones Recommended",
				value: "",
			},
			{
				trait_type: "Press Spacebar to Begin Experience",
				value: "",
			},
			{
				trait_type: "Some Fish Like it when you click on them",
				value: "",
			},
		]
  });

  // Computed values
  readonly currentExhibit = computed(() => this.exhibitState().current);
  readonly exhibitSettings = computed(() => this.exhibitState().settings);
	readonly isLoading = computed(() => this.exhibitState().isLoading);
	readonly symbol = computed(() => this.exhibitState().symbol);
	readonly album = computed(() => this.exhibitState().album);
	readonly knobPosition = computed(() => this.exhibitState().knobPosition);
	readonly prices = computed(() => this.exhibitState().prices);
	readonly price = computed(() => this.exhibitState().price);
	readonly membershipPrice = computed(() => this.exhibitState().membershipPrice);
	readonly admissionPrice = computed(() => this.exhibitState().admissionPrice);
  constructor(private http: HttpClient) {
    // Don't automatically initialize - let the app component control this
		// Remove: this.initializeExhibit();
		
		
		this.getPrices().then(prices => {
			this.exhibitState.update(state => ({
				...state,
				prices, 
				price: prices[this.symbol() as string]
			}));
		});
  }

  // Make this public so it can be called explicitly
  async initializeExhibit() {
    if (!this.exhibitState().current) {  // Only initialize if not already set
			await this.loadExhibitSettings(defaultExhibit);
    }
  }

  async setExhibit(exhibit: string) {
		// Don't reload if it's the same exhibit
		
		if (exhibit === this.exhibitState().current) {
			return;
		}
    this.exhibitState.update(state => ({
      ...state,
      isLoading: true
    }));

    try {
      await this.loadExhibitSettings(exhibit);
      this.exhibitState.update(state => ({
        ...state,
        current: exhibit,
        isLoading: false,
				symbol: this.getSymbol(exhibit),
				album: this.getAlbum(exhibit),
				knobPosition: this.getKnobPosition(exhibit),
				price: this.prices()[this.getSymbol(exhibit) as string],
				membershipPrice: this.getAlbum(exhibit).membershipPrice ?? null,
				admissionPrice: this.getAlbum(exhibit).admissionPrice ?? null,
			}));
			console.log(this.exhibitState().membershipPrice);

    } catch (error) {
      console.error(`Failed to load exhibit: ${exhibit}`, error);
      this.exhibitState.update(state => ({
        ...state,
        isLoading: false
      }));
    }
  }

  private async loadExhibitSettings(exhibit: string) {
    try {
      const settings = await this.http.get<ExhibitSettings>(
        `assets/exhibits/${exhibit}Settings.json`
      ).toPromise();

      if (settings) {
        const presetSettings = settings[Object.keys(settings)[0]];
        this.exhibitState.update(state => ({
          ...state,
          settings: presetSettings
        }));
      }
    } catch (error) {
      console.error(`Failed to load settings for exhibit: ${exhibit}`, error);
      throw error;
    }
	}

	private getSymbol(exhibit: string): string {
		switch (exhibit) {
			case "ethereum": return "ETH";
			case "longbeach": return "LBC";
			case "dogecoin": return "DOGE";
			case "bitcoin": return "BTC";
			case "solana": return "SOL";
			case "base": return "BASE";
			case "polygon": return "MATIC";
			case "montereybay": return "MB";
			default: return "ETH";
		}
	}

	private getAlbum(exhibit: string) {
		switch (exhibit) {
			case "ethereum": return ethereumAlbum;
			case "longbeach": return longbeachAlbum;
			case "dogecoin": return dogeAlbum;
			case "bitcoin": return bitcoinAlbum;
			case "solana": return solanaAlbum;
			case "base": return baseAlbum;
			case "polygon": return polygonAlbum;
			case "montereybay": return montereybayAlbum;
			default: return ethereumAlbum;
		}
	}

	private getKnobPosition(exhibit: string): number {
		return this.getAlbum(exhibit).position;
	}

	// Get Prices
	async getPrices(): Promise<{ [key: string]: number }> {
		const url = new URL(environment.lambda3 + `/y2k/prices`);
		const response = await fetch(url.href);
		return response.json();
	}
	

} 