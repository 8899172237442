{
  "urls": [
    "charlotteSprites.mp3"
  ],
  "sprite": {
    "angelfish": [
      0,
      1488.9795918367347
    ],
    "babyfish": [
      3000,
      1071.0204081632658
    ],
    "betafish": [
      6000,
      1071.0204081632658
    ],
    "blowfish": [
      9000,
      1201.6326530612246
    ],
    "crystal": [
      12000,
      1149.3877551020405
    ],
    "ethereum": [
      15000,
      1071.020408163264
    ],
    "going_down": [
      18000,
      1018.7755102040832
    ],
    "going_up": [
      21000,
      1384.489795918366
    ],
    "goodbye": [
      24000,
      862.0408163265303
    ],
    "hackerfish": [
      26000,
      1097.142857142856
    ],
    "metaquarium": [
      29000,
      1488.9795918367347
    ],
    "mute": [
      32000,
      1071.020408163264
    ],
    "nft": [
      35000,
      783.6734693877575
    ],
    "pause": [
      37000,
      1071.020408163264
    ],
    "play": [
      40000,
      1018.7755102040796
    ],
    "seahorse": [
      43000,
      1018.7755102040796
    ],
    "seaturtle": [
      46000,
      1384.4897959183697
    ],
    "shark": [
      49000,
      1567.3469387755076
    ],
    "teleporting": [
      52000,
      1280.0000000000011
    ],
    "unmute": [
      55000,
      1097.142857142856
    ],
    "welcome_base": [
      58000,
      1515.1020408163233
    ],
    "welcome_bitcoin": [
      61000,
      1671.8367346938762
    ],
    "welcome_dogecoin": [
      64000,
      1671.8367346938835
    ],
    "welcome_ethereum": [
      67000,
      1619.591836734699
    ],
    "welcome_intro_msg": [
      70000,
      17136.326530612252
    ],
    "welcome_longbeach": [
      89000,
      3422.0408163265292
    ],
    "welcome_montereybay": [
      94000,
      2586.1224489795945
    ],
    "welcome_polygon": [
      98000,
      1436.734693877554
    ],
    "welcome_solana": [
      101000,
      1436.734693877554
    ],
    "welcome_to_the_ethereum": [
      104000,
      2168.16326530612
    ],
    "welcome": [
      108000,
      1802.4489795918298
    ]
  }
}