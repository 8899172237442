import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, ViewChild } from "@angular/core";
import { getSynthwaveColor } from "@style/scss-variables";
import { KnobStateService } from "./knob-state.service";

@Component({
	selector: "arcade-knob",
	standalone: false,
	templateUrl: "./knob.component.html",
	styleUrl: "./knob.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArcadeKnobComponent implements AfterViewInit {
	@ViewChild("wrapper") wrapperRef: ElementRef<HTMLElement>;
	@ViewChild("dial") dialRef: ElementRef<HTMLElement>;
	@ViewChild("indicator") dialIndicatorRef: ElementRef<HTMLElement>;

	defaultDetents = [
		{
			label: "one",
			color: getSynthwaveColor(),
			active: true,
			angle: 0,
		},
		{
			label: "two",
			color: getSynthwaveColor(),
			active: false,
			angle: 0,
		},
		{
			label: "three",
			color: getSynthwaveColor(),
			active: false,
			angle: 0,
		},
	];
	@Input() defaultStep: number = 0;
	@Input() detents: any[] = this.defaultDetents;
	@Input() detentScaffold: number;
	@Input() size: number = 400;
	@Input() label: string;

	// Value to emit on step change
	@Output() stepChange = new EventEmitter<string>();
	public step: number = 0;
	public stepName: string = "default";

	detentSize: number = 10;
	dialSize: number = 100;

	isDragging: boolean = false;
	currentAngle: number = 0;

	constructor(
		private renderer: Renderer2,
		private cdr: ChangeDetectorRef,
		private knobStateService: KnobStateService,
	) {}

	ngAfterViewInit() {
		if (this.detentScaffold) {
			this.detents = new Array(this.detentScaffold).fill(0).map((_, index) => {
				return {
					label: `Detent ${index}`,
					color: getSynthwaveColor(),
					active: false,
					angle: 0,
				};
			});

			this.cdr.detectChanges();
		}

		this.updateWrapper();
		this.setDetentStyles();
		this.updateDial();

		// Set initial step/angle based on defaultStep
		if (this.defaultStep !== undefined && this.defaultStep < this.detents.length) {
			this.step = this.defaultStep;
			this.currentAngle = this.detents[this.defaultStep].angle;
			this.renderer.setStyle(this.dialRef.nativeElement, "transform", `rotate(${this.currentAngle}deg)`);
			this.glowDial(this.defaultStep, this.dialRef.nativeElement);
			this.emitStepChange(this.detents[this.defaultStep].label);
		} else {
			this.snapToNearestDetent();
		}

		this.wrapperRef.nativeElement.addEventListener("mousedown", this.onMouseDown.bind(this));
		this.wrapperRef.nativeElement.addEventListener("mousemove", this.onMouseMove.bind(this));
		this.wrapperRef.nativeElement.addEventListener("mouseup", this.onMouseUp.bind(this));
	}

	// host listener for window resize
	@HostListener("window:resize", ["$event"])
	onResize(event) {
		this.updateWrapper();
		this.setDetentStyles();
		this.updateDial();
		// this.snapToNearestDetent();
	}

	updateWrapper() {
		this.wrapperRef.nativeElement.style.width = `${this.size}px`;
		this.wrapperRef.nativeElement.style.height = `${this.size}px`;
	}

	setDetentStyles() {
		const detents = this.wrapperRef.nativeElement.querySelectorAll(".detent");
		this.detentSize = this.size / 10;
		const radius = this.size / 2 - this.detentSize;

		const totalDetents = this.detents.length;
		const validRanges = 2;
		const angleStep = 180 / totalDetents;

		detents.forEach((detent, index) => {
			let angle;
			if (index < totalDetents / validRanges) {
				angle = 270 + index * angleStep;
			} else {
				angle = (index - totalDetents / validRanges) * angleStep;
			}
			const normalizedAngle = (angle + 360) % 360;
			let radians = (normalizedAngle * Math.PI) / 180; // Convert angle to radians

			radians -= Math.PI / 2;
			const x = radius * Math.cos(radians) + this.size / 2 - this.detentSize / 2;
			const y = radius * Math.sin(radians) + this.size / 2 - this.detentSize / 2;

			// this.renderer.setStyle(detent, "transform", `translate(${x}px, ${y}px)`);
			this.renderer.setStyle(detents[index], "left", `${x}px`);
			this.renderer.setStyle(detents[index], "top", `${y}px`);

			// set the angle of the detent
			this.detents[index].angle = normalizedAngle;

			const shadowBlur = this.detentSize * 0.6;
			const detentColor = this.detents[index].color;
			const shadowSpread = this.detentSize * 0.01;
			this.renderer.setStyle(detent, "box-shadow", "0px 0px " + shadowBlur + "px " + shadowSpread + "px " + detentColor);
		});
		//
	}

	glowDial(index: number, detent: any) {
		//
		const shadowBlur = this.detentSize * 0.9;
		const detentColor = this.detents[index].color;
		const shadowSpread = this.detentSize * 0.1;
		this.renderer.setStyle(detent, "box-shadow", "0px 0px " + shadowBlur + "px " + shadowSpread + "px " + detentColor);
	}

	// Update the dial size and indicator position
	updateDial() {
		if (this.dialRef) {
			this.dialSize = this.size / 2;
			this.renderer.setStyle(this.dialRef.nativeElement, "width", `${this.dialSize}px`);
			this.renderer.setStyle(this.dialRef.nativeElement, "height", `${this.dialSize}px`);

			const indicatorWidth = this.dialSize / 10;
			const indicatorHeight = this.dialSize / 5;
			let indicatorTop = this.dialSize / 10;

			if (this.dialIndicatorRef) {
				this.renderer.setStyle(this.dialIndicatorRef.nativeElement, "width", `${indicatorWidth}px`);
				this.renderer.setStyle(this.dialIndicatorRef.nativeElement, "height", `${indicatorHeight}px`);
				this.renderer.setStyle(this.dialIndicatorRef.nativeElement, "top", `${indicatorTop}px`);
			}
		}

		this.cdr.detectChanges();
	}

	onMouseMove(event: MouseEvent) {
		//
		if (this.isDragging) {
			const rect = this.wrapperRef.nativeElement.getBoundingClientRect();
			const centerX = rect.left + rect.width / 2;
			const centerY = rect.top + rect.height / 2;
			const deltaX = event.clientX - centerX;
			const deltaY = event.clientY - centerY;
			const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI) + 90;
			this.currentAngle = angle;
			this.renderer.setStyle(this.dialRef.nativeElement, "transform", `rotate(${angle}deg)`);
		}
	}

	onMouseUp() {
		if (this.isDragging) {
			this.isDragging = false;
			this.snapToNearestDetent();
		}
	}

	onMouseDown() {
		this.isDragging = true;
	}

	snapToNearestDetent() {
		const detentAngles = this.detents.map((detent) => detent.angle);
		const normalizedDetentAngles = detentAngles.map((angle) => (angle + 360) % 360); // Normalize detent angles to range 0 to 360
		//
		//

		const normalizedCurrentAngle = (this.currentAngle + 360) % 360; // Normalize current angle to range 0 to 360
		//
		const nearestDetentAngle = normalizedDetentAngles.reduce((prev, curr) => {
			return Math.abs(curr - normalizedCurrentAngle) < Math.abs(prev - normalizedCurrentAngle) ? curr : prev;
		});

		this.currentAngle = nearestDetentAngle;
		//
		this.renderer.setStyle(this.dialRef.nativeElement, "transform", `rotate(${nearestDetentAngle}deg)`);

		this.glowDial(normalizedDetentAngles.indexOf(nearestDetentAngle), this.dialRef.nativeElement);

		// detect if the step is the same as the current step
		if (this.step === normalizedDetentAngles.indexOf(nearestDetentAngle)) {
			return;
		}

		this.step = normalizedDetentAngles.indexOf(nearestDetentAngle);

		this.emitStepChange(this.detents[this.step].label);
	}

	emitStepChange(_step: string) {
		this.stepName = _step;
		this.stepChange.emit(this.stepName);
	}
}
