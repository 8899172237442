import { albumS3Url } from "./constants";
import { Album } from "./types";

// Each track has a scene configuration
// Each track has a breed configuration
// Each breed configuration has a fish type and population

export const ethereumAlbum: Album = {
	name: "Ethereum",
	exhibit: "ethereum",
	foundingDate: "July 30, 2015",
	position: 4,
	tracks: [
		{
			id: 1,
			title: "00_ζo))))彡.wav",
			slug: "ethereum-1",
			bin: true,
			scene: {
				init: {
					camera: {
						position: {
							x: 0,
							y: 0,
							z: 0,
						},
						rotation: {
							x: 0,
							y: 0,
							z: 0,
						},
					},
					water: {
						rotation: {
							x: 0.0,
							y: 0.0,
							z: 0.0,
						},
						material: {
							color: {
								r: 0.0,
								g: 0.0,
								b: 0.0,
							},
						},
					},
					breeds: [
						{ shark: 9 },
						{ seahorse: 5 },
						{ crab: 5 },
						{ betafish: 0 },
						{ dori: 1 },
						{ seaturtle: 5 },
						{ glowfish: 20 },
						{ angelfish: 10 },
						{ babyfish: 3 },
						{ blowfish: 7 },
						{ hackerfish: 10 },
					],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "ethereum/web/8.mp3",
			webm: albumS3Url + "ethereum/web/8.webm",
			flac: albumS3Url + "ethereum/hifi/8.flac",
			howl: null,
			schoolSize: 3,
			schools: 5,
			guiParams: null,
			rotation: {
				x: 0.0,
				y: -Math.PI / 3,
				z: 0.0,
			},
			animate: {
				oscilate: {
					x: 0.05,
					y: 0.05,
					z: 0.05,
				},
				movement: {
					x: 0.1,
					y: 0.0,
					z: 0,
				},
			},
		},
		{
			id: 2,
			title: "01_ʚʘ͡))彡.wav",
			slug: "ethereum-2",
			scene: {
				init: {
					camera: {
						position: {
							x: 0,
							y: 0,
							z: 0,
						},
						rotation: {
							x: 0,
							y: Math.PI / 4,
							z: 0,
						},
						curve: "spiral",
					},
					water: {
						rotation: {
							x: 0.0,
							y: 0.0,
							z: 0.0,
						},
						material: {
							color: {
								r: 0.0,
								g: 0.0,
								b: 0.0,
							},
						},
					},
					breeds: [
						{ shark: 4 },
						{ seahorse: 30 },
						{ crab: 15 },
						{ betafish: 0 },
						{ dori: 1 },
						{ seaturtle: 0 },
						{ glowfish: 30 },
						{ angelfish: 15 },
						{ babyfish: 1 },
						{ blowfish: 2 },
						{ hackerfish: 10 },
					],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "ethereum/web/4.mp3",
			webm: albumS3Url + "ethereum/web/4.webm",
			flac: albumS3Url + "ethereum/hifi/4.flac",
			howl: null,
			schoolSize: 4,
			schools: 5,
			guiParams: null,
			animate: {
				oscilate: {
					x: 0.05,
					y: 0.05,
					z: 0.05,
				},
				movement: {
					x: 0.01,
					y: 0.0,
					z: 0.0,
				},
			},
		},
		{
			id: 3,
			title: "02_(/)!_!(/).wav",
			slug: "ethereum-3",
			scene: {
				init: {
					camera: {
						position: {
							x: 0,
							y: 0,
							z: 0,
						},
						rotation: {
							x: 0,
							y: 0,
							z: 0,
						},
					},
					water: {
						rotation: {
							x: 0.0,
							y: 0.0,
							z: 0.0,
						},
						material: {
							color: {
								r: 0.0,
								g: 0.0,
								b: 0.0,
							},
						},
					},
					breeds: [
						{ shark: 2 },
						{ seahorse: 2 },
						{ crab: 10 },
						{ betafish: 0 },
						{ dori: 14 },
						{ seaturtle: 0 },
						{ glowfish: 30 },
						{ angelfish: 25 },
						{ babyfish: 1 },
						{ blowfish: 31 },
						{ hackerfish: 10 },
					],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "ethereum/web/5.mp3",
			webm: albumS3Url + "ethereum/web/5.webm",
			flac: albumS3Url + "ethereum/hifi/5.flac",
			howl: null,
			schoolSize: 4,
			schools: 8,
			guiParams: null,
			animate: {
				oscilate: {
					x: 0.25,
					y: 0.15,
					z: 0.25,
				},
				movement: {
					x: 0.05,
					y: 0.05,
					z: 0.05,
				},
			},
		},
		{
			id: 4,
			title: "03_ε( 'Θ' )϶.wav",
			slug: "ethereum-4",
			scene: {
				init: {
					camera: {
						position: {
							x: 0,
							y: 0,
							z: 0,
						},
						rotation: {
							x: 0,
							y: 0,
							z: 0,
						},
					},
					water: {
						rotation: {
							x: 0.0,
							y: 0.0,
							z: 0.0,
						},
						material: {
							color: {
								r: 0.0,
								g: 0.0,
								b: 0.0,
							},
							showWireframe: true,
						},
					},
					breeds: [
						{ shark: 7 },
						{ seahorse: 20 },
						{ crab: 0 },
						{ betafish: 0 },
						{ dori: 20 },
						{ seaturtle: 40 },
						{ glowfish: 20 },
						{ angelfish: 5 },
						{ babyfish: 9 },
						{ blowfish: 0 },
						{ hackerfish: 10 },
					],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "ethereum/web/6.mp3",
			webm: albumS3Url + "ethereum/web/6.webm",
			flac: albumS3Url + "ethereum/hifi/6.flac",
			howl: null,
			schoolSize: 4,
			schools: 9,
			guiParams: null,
			animate: {
				oscilate: {
					x: 0.15,
					y: 0.15,
					z: 0.15,
				},
				movement: {
					x: 0.01,
					y: 0,
					z: 0.0,
				},
			},
		},
		{
			id: 5,
			title: "04⋙l;((((*≿.wav",
			slug: "ethereum-5",
			scene: {
				init: {
					camera: {
						position: {
							x: 0,
							y: 0,
							z: 0,
						},
						rotation: {
							x: 0,
							y: 0,
							z: 0,
						},
					},
					water: {
						rotation: {
							x: 0.0,
							y: 0.0,
							z: 0.0,
						},
						material: {
							color: {
								r: 0.0,
								g: 0.0,
								b: 0.0,
							},
							showWireframe: true,
						},
					},
					breeds: [
						{ shark: 1 },
						{ seahorse: 1 },
						{ crab: 5 },
						{ betafish: 0 },
						{ dori: 12 },
						{ seaturtle: 8 },
						{ glowfish: 30 },
						{ angelfish: 5 },
						{ babyfish: 10 },
						{ blowfish: 13 },
						{ hackerfish: 10 },
					],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "ethereum/web/7.mp3",
			webm: albumS3Url + "ethereum/web/7.webm",
			flac: albumS3Url + "ethereum/hifi/7.flac",
			howl: null,
			schoolSize: 10,
			schools: 8,
			guiParams: null,
			animate: {
				oscilate: {
					x: 0.05,
					y: 0.05,
					z: 0.05,
				},
				movement: {
					x: 0.01,
					y: 0,
					z: 0.0,
				},
			},
		},
		{
			id: 6,
			title: "05_くコ∷彡.wav",
			slug: "ethereum-6",
			scene: {
				init: {
					camera: {
						position: {
							x: 0,
							y: 0,
							z: 0,
						},
						rotation: {
							x: 0,
							y: 0,
							z: 0,
						},
					},
					water: {
						rotation: {
							x: 0.0,
							y: 0.0,
							z: 0.0,
						},
						material: {
							color: {
								r: 0.0,
								g: 0.0,
								b: 0.0,
							},
							showWireframe: true,
						},
					},
					breeds: [
						{ shark: 1 },
						{ seahorse: 0 },
						{ crab: 35 },
						{ betafish: 0 },
						{ dori: 25 },
						{ seaturtle: 0 },
						{ glowfish: 40 },
						{ angelfish: 25 },
						{ babyfish: 1 },
						{ blowfish: 0 },
						{ hackerfish: 0 },
					],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "ethereum/web/2.mp3",
			webm: albumS3Url + "ethereum/web/2.webm",
			flac: albumS3Url + "ethereum/hifi/2.flac",
			howl: null,
			schoolSize: 4,
			schools: 8,
			guiParams: null,
			animate: {
				oscilate: {
					x: 0.01,
					y: 0.01,
					z: 0.01,
				},
				movement: {
					x: 0.01,
					y: 0,
					z: 0.0,
				},
			},
		},
		{
			id: 7,
			title: "06_≼Θ)))))⋊.wav",
			slug: "ethereum-7",
			scene: {
				init: {
					camera: {
						position: {
							x: 0,
							y: 0,
							z: 0,
						},
						rotation: {
							x: 0,
							y: 0,
							z: 0,
						},
					},
					water: {
						rotation: {
							x: 0.0,
							y: 0.0,
							z: 0.0,
						},
						material: {
							color: {
								r: 0.0,
								g: 0.0,
								b: 0.0,
							},
							showWireframe: true,
						},
					},
					breeds: [
						{ shark: 8 },
						{ seahorse: 15 },
						{ crab: 5 },
						{ betafish: 0 },
						{ dori: 1 },
						{ seaturtle: 10 },
						{ glowfish: 10 },
						{ angelfish: 3 },
						{ babyfish: 10 },
						{ blowfish: 30 },
						{ hackerfish: 20 },
					],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "ethereum/web/1.mp3",
			webm: albumS3Url + "ethereum/web/1.webm",
			flac: albumS3Url + "ethereum/hifi/1.flac",
			howl: null,
			schoolSize: 4,
			schools: 8,
			guiParams: null,
			animate: {
				oscilate: {
					x: 0.09,
					y: 0.05,
					z: 0.07,
				},
				movement: {
					x: 0.01,
					y: 0.01,
					z: 0.01,
				},
			},
		},
		{
			id: 8,
			title: "07_{;☄` ̧ ̧. ́ ̄`·.wav",
			slug: "ethereum-8",
			scene: {
				init: {
					camera: {
						position: {
							x: 0,
							y: 0,
							z: 0,
						},
						rotation: {
							x: 0,
							y: 0,
							z: 0,
						},
					},
					water: {
						rotation: {
							x: 0.0,
							y: 0.0,
							z: 0.0,
						},
						material: {
							color: {
								r: 0.0,
								g: 0.0,
								b: 0.0,
							},
							showWireframe: true,
						},
					},
					breeds: [
						{ shark: 4 },
						{ seahorse: 2 },
						{ crab: 45 },
						{ betafish: 0 },
						{ dori: 12 },
						{ seaturtle: 0 },
						{ glowfish: 10 },
						{ angelfish: 5 },
						{ babyfish: 50 },
						{ blowfish: 30 },
						{ hackerfish: 3 },
					],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "ethereum/web/3.mp3",
			webm: albumS3Url + "ethereum/web/3.webm",
			flac: albumS3Url + "ethereum/hifi/3.flac",
			howl: null,
			schoolSize: 4,
			schools: 8,
			guiParams: null,
			animate: {
				oscilate: {
					x: 0.05,
					y: 0.05,
					z: 0,
				},
				movement: {
					x: 0.01,
					y: 0,
					z: 0.0,
				},
			},
			gravity: true,
		},
	],
};

export const dogeAlbum: Album = {
	name: "Doge",
	exhibit: "dogecoin",
	foundingDate: "December 6, 2013",
	position: 1,
	tracks: [
		{
			id: 1,
			title: "Much Swim",
			slug: "doge-1",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 100, z: -200 },
						rotation: { x: 0, y: 0, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 1, g: 1.0, b: 0.8 },
							opacity: 0.6,
						},
					},
					breeds: [{ "doge-sprite": 90 }, { glowfish: 40 }, { babyfish: 30 }],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "dogecoin/web/1_much_swim.mp3",
			webm: albumS3Url + "dogecoin/web/1_much_swim.webm",
			flac: albumS3Url + "dogecoin/hifi/1_much_swim.flac",
			howl: null,
			schoolSize: 5,
			schools: 6,
			guiParams: null,
			rotation: {
				x: 0.0,
				y: -Math.PI / 3,
				z: 0.0,
			},
			animate: {
				oscilate: { x: 0.05, y: 0.05, z: 0.05 },
				movement: { x: 0.1, y: 0.0, z: 0 },
			},
			gravity: false,
		},
		{
			id: 2,
			title: "Very Bubble",
			slug: "doge-2",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 150, z: -250 },
						rotation: { x: 0, y: Math.PI / 6, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 0.3, g: 0.5, b: 0.9 },
							opacity: 0.7,
						},
					},
					breeds: [{ "doge-sprite": 65 }, { glowfish: 15 }, { babyfish: 20 }],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "dogecoin/web/2_very_bubble.mp3",
			webm: albumS3Url + "dogecoin/web/2_very_bubble.webm",
			flac: albumS3Url + "dogecoin/hifi/2_very_bubble.flac",
			howl: null,
			schoolSize: 4,
			schools: 7,
			guiParams: null,
			rotation: {
				x: 0.0,
				y: Math.PI / 4,
				z: 0.0,
			},
			animate: {
				oscilate: { x: 0.08, y: 0.08, z: 0.08 },
				movement: { x: 0.15, y: 0.05, z: 0.05 },
			},
			gravity: false,
		},
		{
			id: 3,
			title: "Wow Ocean",
			slug: "doge-3",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 120, z: -180 },
						rotation: { x: 0, y: -Math.PI / 8, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 0.1, g: 0.3, b: 0.7 },
							opacity: 0.65,
						},
					},
					breeds: [{ "doge-sprite": 95 }, { glowfish: 20 }, { babyfish: 25 }],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "dogecoin/web/3_wow_ocean.mp3",
			webm: albumS3Url + "dogecoin/web/3_wow_ocean.webm",
			flac: albumS3Url + "dogecoin/hifi/3_wow_ocean.flac",
			howl: null,
			schoolSize: 6,
			schools: 5,
			guiParams: null,
			rotation: {
				x: 0.0,
				y: 0.0,
				z: 0.0,
			},
			animate: {
				oscilate: { x: 0.1, y: 0.1, z: 0.1 },
				movement: { x: 0.12, y: 0.08, z: 0.08 },
			},
			gravity: false,
		},
		{
			id: 4,
			title: "Such Float",
			slug: "doge-4",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 130, z: -220 },
						rotation: { x: 0, y: Math.PI / 5, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 0.2, g: 0.5, b: 0.8 },
							opacity: 0.7,
						},
					},
					breeds: [{ "doge-sprite": 70 }, { glowfish: 25 }, { babyfish: 30 }],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "dogecoin/web/4_such_float.mp3",
			webm: albumS3Url + "dogecoin/web/4_such_float.webm",
			flac: albumS3Url + "dogecoin/hifi/4_such_float.flac",
			howl: null,
			schoolSize: 5,
			schools: 8,
			guiParams: null,
			rotation: {
				x: 0.0,
				y: Math.PI / 3,
				z: 0.0,
			},
			animate: {
				oscilate: { x: 0.07, y: 0.07, z: 0.07 },
				movement: { x: 0.08, y: 0.06, z: 0.06 },
			},
			gravity: false,
		},
	],
};

export const bitcoinAlbum: Album = {
	name: "Bitcoin",
	exhibit: "bitcoin",
	foundingDate: "January 3, 2009",
	position: 2,
	tracks: [
		{
			id: 1,
			title: "Satoshi's Vision",
			slug: "bitcoin-1",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 100, z: -200 },
						rotation: { x: 0, y: 0, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 1, g: 0.8, b: 0.2 },
							opacity: 0.6,
						},
					},
					breeds: [{ shark: 3 }, { seahorse: 15 }, { glowfish: 25 }, { angelfish: 20 }, { babyfish: 10 }, { blowfish: 8 }, { seaturtle: 5 }],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "bitcoin/web/1_satoshis_vision.mp3",
			webm: albumS3Url + "bitcoin/web/1_satoshis_vision.webm",
			flac: albumS3Url + "bitcoin/hifi/1_satoshis_vision.flac",
			howl: null,
			schoolSize: 5,
			schools: 6,
			guiParams: null,
			rotation: { x: 0.0, y: -Math.PI / 3, z: 0.0 },
			animate: {
				oscilate: { x: 0.05, y: 0.05, z: 0.05 },
				movement: { x: 0.1, y: 0.0, z: 0 },
			},
			gravity: false,
		},
		{
			id: 2,
			title: "Digital Gold",
			slug: "bitcoin-2",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 150, z: -250 },
						rotation: { x: 0, y: Math.PI / 6, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 0.9, g: 0.7, b: 0.1 },
							opacity: 0.7,
						},
					},
					breeds: [{ seahorse: 25 }, { babyfish: 30 }, { dori: 15 }, { blowfish: 10 }],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "bitcoin/web/2_digital_gold.mp3",
			webm: albumS3Url + "bitcoin/web/2_digital_gold.webm",
			flac: albumS3Url + "bitcoin/hifi/2_digital_gold.flac",
			howl: null,
			schoolSize: 4,
			schools: 7,
			guiParams: null,
			rotation: { x: 0.0, y: Math.PI / 4, z: 0.0 },
			animate: {
				oscilate: { x: 0.08, y: 0.08, z: 0.08 },
				movement: { x: 0.15, y: 0.05, z: 0.05 },
			},
			gravity: false,
		},
		{
			id: 3,
			title: "Blockchain Dreams",
			slug: "bitcoin-3",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 120, z: -180 },
						rotation: { x: 0, y: -Math.PI / 8, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 1.0, g: 0.6, b: 0.1 },
							opacity: 0.65,
						},
					},
					breeds: [{ betafish: 20 }, { glowfish: 20 }, { angelfish: 15 }, { crab: 8 }],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "bitcoin/web/3_blockchain_dreams.mp3",
			webm: albumS3Url + "bitcoin/web/3_blockchain_dreams.webm",
			flac: albumS3Url + "bitcoin/hifi/3_blockchain_dreams.flac",
			howl: null,
			schoolSize: 6,
			schools: 5,
			guiParams: null,
			rotation: { x: 0.0, y: 0.0, z: 0.0 },
			animate: {
				oscilate: { x: 0.1, y: 0.1, z: 0.1 },
				movement: { x: 0.12, y: 0.08, z: 0.08 },
			},
			gravity: false,
		},
	],
};

export const solanaAlbum: Album = {
	name: "Solana",
	exhibit: "solana",
	foundingDate: "March 16, 2020",
	position: 0,
	tracks: [
		{
			id: 1,
			title: "Proof of Vibes",
			slug: "solana-1",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 100, z: -200 },
						rotation: { x: 0, y: 0, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							colorStart: { r: 0.6, g: 0.27, b: 1.0 },
							colorEnd: { r: 0.08, g: 0.95, b: 0.58 },
							opacity: 0.7
						}
					},
					breeds: [
						{ jellyfish: 750 },
						{ glowfish: 25 },
						{ seaturtle: 15 },
					],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "solana/web/1_proof_of_vibes.mp3",
			webm: albumS3Url + "solana/web/1_proof_of_vibes.webm",
			flac: albumS3Url + "solana/hifi/1_proof_of_vibes.flac",
			howl: null,
			schoolSize: 5,
			schools: 6,
			guiParams: null,
			rotation: { x: 0.0, y: -Math.PI / 3, z: 0.0 },
			animate: {
				oscilate: { x: 0.05, y: 0.05, z: 0.05 },
				movement: { x: 0.1, y: 0.0, z: 0 },
			},
			gravity: false,
		},
		{
			id: 2,
			title: "Digital Waves",
			slug: "solana-2",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 150, z: -250 },
						rotation: { x: 0, y: Math.PI / 6, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							colorStart: { r: 0.3, g: 0.1, b: 0.9 },
							colorEnd: { r: 0.08, g: 0.95, b: 0.58 },
							opacity: 0.7
						}
					},
					breeds: [
						{ jellyfish: 120 },
						{ angelfish: 20 },
						{ seaturtle: 5 },
					],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "solana/web/2_digital_waves.mp3",
			webm: albumS3Url + "solana/web/2_digital_waves.webm",
			flac: albumS3Url + "solana/hifi/2_digital_waves.flac",
			howl: null,
			schoolSize: 4,
			schools: 7,
			guiParams: null,
			rotation: { x: 0.0, y: Math.PI / 4, z: 0.0 },
			animate: {
				oscilate: { x: 0.08, y: 0.08, z: 0.08 },
				movement: { x: 0.15, y: 0.05, z: 0.05 },
			},
			gravity: false,
		},
		{
			id: 3,
			title: "Fast Dreams",
			slug: "solana-3",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 120, z: -180 },
						rotation: { x: 0, y: -Math.PI / 8, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							colorStart: { r: 0.5, g: 0.2, b: 0.7 },
							colorEnd: { r: 0.08, g: 0.95, b: 0.58 },
							opacity: 0.65
						}
					},
					breeds: [
						{ jellyfish: 150 },
						{ blowfish: 30 },
						{ shark: 3 },
					],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "solana/web/3_fast_dreams.mp3",
			webm: albumS3Url + "solana/web/3_fast_dreams.webm",
			flac: albumS3Url + "solana/hifi/3_fast_dreams.flac",
			howl: null,
			schoolSize: 6,
			schools: 5,
			guiParams: null,
			rotation: { x: 0.0, y: 0.0, z: 0.0 },
			animate: {
				oscilate: { x: 0.1, y: 0.1, z: 0.1 },
				movement: { x: 0.12, y: 0.08, z: 0.08 },
			},
			gravity: false,
		},
	],
};

export const polygonAlbum: Album = {
	name: "Polygon",
	exhibit: "polygon",
	foundingDate: "October 2, 2017",
	position: 3,
	tracks: [
		{
			id: 1,
			title: "Layer 2 Dreams",
			slug: "polygon-1",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 100, z: -200 },
						rotation: { x: 0, y: 0, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 0.6, g: 0.2, b: 0.8 },
							opacity: 0.6,
						},
					},
					breeds: [{ glowfish: 35 }, { angelfish: 25 }, { seahorse: 20 }, { babyfish: 15 }, { dori: 10 }, { blowfish: 8 }, { crab: 5 }],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "polygon/web/1_layer_2_dreams.mp3",
			webm: albumS3Url + "polygon/web/1_layer_2_dreams.webm",
			flac: albumS3Url + "polygon/hifi/1_layer_2_dreams.flac",
			howl: null,
			schoolSize: 5,
			schools: 6,
			guiParams: null,
			rotation: { x: 0.0, y: -Math.PI / 3, z: 0.0 },
			animate: {
				oscilate: { x: 0.05, y: 0.05, z: 0.05 },
				movement: { x: 0.1, y: 0.0, z: 0 },
			},
			gravity: false,
		},
		{
			id: 2,
			title: "Scaling Solutions",
			slug: "polygon-2",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 150, z: -250 },
						rotation: { x: 0, y: Math.PI / 6, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 0.7, g: 0.2, b: 0.9 },
							opacity: 0.7,
						},
					},
					breeds: [{ babyfish: 30 }, { dori: 20 }, { blowfish: 15 }],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "polygon/web/2_scaling_solutions.mp3",
			webm: albumS3Url + "polygon/web/2_scaling_solutions.webm",
			flac: albumS3Url + "polygon/hifi/2_scaling_solutions.flac",
			howl: null,
			schoolSize: 4,
			schools: 7,
			guiParams: null,
			rotation: { x: 0.0, y: Math.PI / 4, z: 0.0 },
			animate: {
				oscilate: { x: 0.08, y: 0.08, z: 0.08 },
				movement: { x: 0.15, y: 0.05, z: 0.05 },
			},
			gravity: false,
		},
		{
			id: 3,
			title: "MATIC Magic",
			slug: "polygon-3",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 120, z: -180 },
						rotation: { x: 0, y: -Math.PI / 8, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 0.8, g: 0.3, b: 0.9 },
							opacity: 0.65,
						},
					},
					breeds: [{ betafish: 25 }, { glowfish: 30 }, { seaturtle: 10 }],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "polygon/web/3_matic_magic.mp3",
			webm: albumS3Url + "polygon/web/3_matic_magic.webm",
			flac: albumS3Url + "polygon/hifi/3_matic_magic.flac",
			howl: null,
			schoolSize: 6,
			schools: 5,
			guiParams: null,
			rotation: { x: 0.0, y: 0.0, z: 0.0 },
			animate: {
				oscilate: { x: 0.1, y: 0.1, z: 0.1 },
				movement: { x: 0.12, y: 0.08, z: 0.08 },
			},
			gravity: false,
		},
	],
};

export const baseAlbum: Album = {
	name: "Base",
	exhibit: "base",
	foundingDate: "February 23, 2023",
	position: 5,
	tracks: [
		{
			id: 1,
			title: "Coinbase Puzzle",
			slug: "base-1",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 100, z: -200 },
						rotation: { x: 0, y: 0, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 0.0, g: 0.4, b: 0.9 },
							opacity: 0.6,
						},
					},
					breeds: [{ seahorse: 35 }, { angelfish: 25 }, { babyfish: 20 }],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "base/web/1_coinbase_puzzle.mp3",
			webm: albumS3Url + "base/web/1_coinbase_puzzle.webm",
			flac: albumS3Url + "base/hifi/1_coinbase_puzzle.flac",
			howl: null,
			schoolSize: 5,
			schools: 6,
			guiParams: null,
			rotation: { x: 0.0, y: -Math.PI / 3, z: 0.0 },
			animate: {
				oscilate: { x: 0.05, y: 0.05, z: 0.05 },
				movement: { x: 0.1, y: 0.0, z: 0 },
			},
			gravity: false,
		},
		{
			id: 2,
			title: "Layer 2 Synthesis",
			slug: "base-2",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 150, z: -250 },
						rotation: { x: 0, y: Math.PI / 6, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 0.1, g: 0.5, b: 1.0 },
							opacity: 0.7,
						},
					},
					breeds: [{ betafish: 30 }, { glowfish: 25 }, { dori: 15 }, { angelfish: 15 }, { seahorse: 12 }, { babyfish: 10 }, { seaturtle: 5 }],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "base/web/2_layer_2_synthesis.mp3",
			webm: albumS3Url + "base/web/2_layer_2_synthesis.webm",
			flac: albumS3Url + "base/hifi/2_layer_2_synthesis.flac",
			howl: null,
			schoolSize: 4,
			schools: 7,
			guiParams: null,
			rotation: { x: 0.0, y: Math.PI / 4, z: 0.0 },
			animate: {
				oscilate: { x: 0.08, y: 0.08, z: 0.08 },
				movement: { x: 0.15, y: 0.05, z: 0.05 },
			},
			gravity: false,
		},
		{
			id: 3,
			title: "Optimism Waves",
			slug: "base-3",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 120, z: -180 },
						rotation: { x: 0, y: -Math.PI / 8, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 0.2, g: 0.3, b: 0.8 },
							opacity: 0.65,
						},
					},
					breeds: [{ seaturtle: 12 }, { glowfish: 25 }, { angelfish: 20 }],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "base/web/3_optimism_waves.mp3",
			webm: albumS3Url + "base/web/3_optimism_waves.webm",
			flac: albumS3Url + "base/hifi/3_optimism_waves.flac",
			howl: null,
			schoolSize: 6,
			schools: 5,
			guiParams: null,
			rotation: { x: 0.0, y: 0.0, z: 0.0 },
			animate: {
				oscilate: { x: 0.1, y: 0.1, z: 0.1 },
				movement: { x: 0.12, y: 0.08, z: 0.08 },
			},
			gravity: false
		},
	],
};

export const longbeachAlbum: Album = {
	name: "Long Beach",
	exhibit: "longbeach",
	foundingDate: "June 20, 1998",
	cta: {
		action: "Visit the Aquarium",
		type: "url",
		link: "https://tickets.aquariumofpacific.org/webstore/shop/viewitems.aspx?cg=aopweb2&c=aoppv2",
	},
	position: 6,
	membershipPrice: 119.00,
	admissionPrice: 44.95,
	tracks: [
		{
			id: 1,
			title: "aquarium of the pacific",
			slug: "longbeach-1",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 100, z: -200 },
						rotation: { x: 0, y: 0, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 0.6, g: 0.2, b: 0.8 },
							opacity: 0.6,
						},
					},
					breeds: [
						{ "glowfish": 35 },
						{ "angelfish": 25 },
						{ "seahorse": 20 },
						{ "babyfish": 15 },
						{ "dori": 10 },
						{ "blowfish": 8 },
						{ "crab": 5 }
					],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "longbeach/web/1_aquarium_of_the_pacific.mp3",
			webm: albumS3Url + "longbeach/web/1_aquarium_of_the_pacific.webm",
			flac: albumS3Url + "longbeach/hifi/1_aquarium_of_the_pacific.flac",
			howl: null,
			schoolSize: 5,
			schools: 6,
			guiParams: null,
			rotation: { x: 0.0, y: -Math.PI / 3, z: 0.0 },
			animate: {
				oscilate: { x: 0.05, y: 0.05, z: 0.05 },
				movement: { x: 0.1, y: 0.0, z: 0 },
			},
			gravity: false,
			youtubeScreens: [
				{
					id: "ULoT2kODKlY",
					title: "Penguin Beach",
					position: { x: 0, y: 30, z: 150 },
					rotation: { x: 0, y: 0, z: 0 },
					scale: { x: 1, y: 1, z: 1 }
				},
				{
					id: "eXfpXKP6qVE",
					title: "Penguin Habitat",
					position: { x: 0, y: 30, z: 150 },
					rotation: { x: 0, y: 0, z: 0 },
					scale: { x: 1, y: 1, z: 1 }
				},
				{
					id: "yuhnCtTPtZo",
					title: "Blue Cavern",
					position: { x: 20, y: 10, z: 0 },
					rotation: { x: 0, y: 0, z: 0 },
					scale: { x: 1, y: 1, z: 1 }
				},
				{
					id: "BLvr4K2eiRE",
					title: "Shark Lagoon",
					position: { x: -20, y: 30, z: 180 },
					rotation: { x: 0, y: 0, z: 0 },
					scale: { x: 1, y: 1, z: 1 }
				},
				{
					id: "1rvCfsW_qTA",
					title: "Jellyfish Cam",
					position: { x: 20, y: 10, z: 0 },
					rotation: { x: 0, y: 0, z: 0 },
					scale: { x: 1, y: 1, z: 1 }
				},
				{
					id: "DHUnz4dyb54",
					title: "Tropical Reef",
					position: { x: 20, y: 10, z: 0 },
					rotation: { x: 0, y: 0, z: 0 },
					scale: { x: 1, y: 1, z: 1 }
				},
				{
					id: "kkYybcn5VoM",
					title: "Coral Predators",
					position: { x: 20, y: 10, z: 0 },
					rotation: { x: 0, y: 0, z: 0 },
					scale: { x: 1, y: 1, z: 1 }
				},
			]
		}
	],
};

export const montereybayAlbum: Album = {
	name: "Monterey Bay",
	exhibit: "montereybay",
	foundingDate: "October 20, 1984",
	position: 7,
	cta: {
		action: "Visit the Aquarium",
		type: "url",
		link: "https://www.montereybayaquarium.org/visit/admission-tickets",
	},
	membershipPrice: 125.00,
	admissionPrice: 50.00,
	tracks: [
		{
			id: 1,
			title: "aquarium of monterey bay",
			slug: "montereybay-1",
			bin: true,
			scene: {
				init: {
					camera: {
						position: { x: 0, y: 100, z: -200 },
						rotation: { x: 0, y: 0, z: 0 },
						curve: undefined,
					},
					water: {
						rotation: { x: 0, y: 80, z: 0 },
						material: {
							color: { r: 0.6, g: 0.2, b: 0.8 },
							opacity: 0.6,
						},
					},
					breeds: [
						{ "glowfish": 35 },
						{ "angelfish": 25 },
						{ "seahorse": 20 },
						{ "babyfish": 15 },
						{ "dori": 10 },
						{ "blowfish": 8 },
						{ "crab": 5 }
					],
					floor: {
						shader: {
							name: "GRADIENT",
							uniforms: {
								colorStart: { value: [0.6, 0.27, 1.0] },
								colorEnd: { value: [0.08, 0.95, 0.58] }
							}
						}
					}
				},
			},
			mp3: albumS3Url + "montereybay/web/1_aquarium_of_monterey.mp3",
			webm: albumS3Url + "montereybay/web/1_aquarium_of_monterey.webm",
			flac: albumS3Url + "montereybay/hifi/1_aquarium_of_monterey.flac",
			howl: null,
			schoolSize: 5,
			schools: 6,
			guiParams: null,
			rotation: { x: 0.0, y: -Math.PI / 3, z: 0.0 },
			animate: {
				oscilate: { x: 0.05, y: 0.05, z: 0.05 },
				movement: { x: 0.1, y: 0.0, z: 0 },
			},
			gravity: false,
			youtubeScreens: [
				{
					id: "OMlf71t2oV0",
					title: "Jelly Cam",
					position: { x: 0, y: 30, z: 150 },
					rotation: { x: 0, y: 0, z: 0 },
					scale: { x: 1, y: 1, z: 1 }
				},
				{
					id: "fuCeRkeDxtQ",
					title: "Bay Cam",
					position: { x: 0, y: 30, z: 150 },
					rotation: { x: 0, y: 0, z: 0 },
					scale: { x: 1, y: 1, z: 1 }
				},
				{
					id: "dzmJXWmA2EM",
					title: "Spider Crab Cam",
					position: { x: 20, y: 10, z: 0 },
					rotation: { x: 0, y: 0, z: 0 },
					scale: { x: 1, y: 1, z: 1 }
				},
				{
					id: "abbR-Ttd-cA",
					title: "Sea Otter Cam",
					position: { x: -20, y: 30, z: 180 },
					rotation: { x: 0, y: 0, z: 0 },
					scale: { x: 1, y: 1, z: 1 }
				},
				{
					id: "w3LjpFhySTg",
					title: "Kelp Forest Cam",
					position: { x: 20, y: 10, z: 0 },
					rotation: { x: 0, y: 0, z: 0 },
					scale: { x: 1, y: 1, z: 1 }
				},
				{
					id: "tEtg5Kg3voQ",
					title: "Shark Cam",
					position: { x: 20, y: 10, z: 0 },
					rotation: { x: 0, y: 0, z: 0 },
					scale: { x: 1, y: 1, z: 1 }
				},
				{
					id: "gfe7xNLFY50",
					title: "African Penguin Cam",
					position: { x: 20, y: 10, z: 0 },
					rotation: { x: 0, y: 0, z: 0 },
					scale: { x: 1, y: 1, z: 1 }
				},
				{
					id: "73k27SEQVTU",
					title: "Moon Jelly Cam",
					position: { x: 20, y: 10, z: 0 },
					rotation: { x: 0, y: 0, z: 0 },
					scale: { x: 1, y: 1, z: 1 }
				},
				{
					id: "cUoet3dmRU4",
					title: "Aviary Cam",
					position: { x: 20, y: 10, z: 0 },
					rotation: { x: 0, y: 0, z: 0 },
					scale: { x: 1, y: 1, z: 1 }
				},
			]
		}
	],
};

