import Variables, { getSynthwaveColor } from "@style/scss-variables";
import { Device, VideoSrcSet } from "./types";

export const defaultExhibit = "ethereum";

export const consoleHeight = parseInt(Variables.consoleHeight.substring(0, Variables.consoleHeight.length - 2));
export const navHeight = parseInt(Variables.navHeight.substring(0, Variables.navHeight.length - 2));

export const albumS3Url = "https://s3.us-west-2.amazonaws.com/metaquarium.art/albums/";
export const videoS3Url = "https://s3.us-west-2.amazonaws.com/metaquarium.art/video";
export const mp4S3Url = "https://s3.us-west-2.amazonaws.com/metaquarium.art/mp4";
export const dracoStaticPath = "https://www.gstatic.com/draco/versioned/decoders/1.5.7/";
export const awsBaseApi = "https://xi9uz1w484.execute-api.us-west-1.amazonaws.com/staging/";
export const charlotteSpritesUrl = "https://s3.us-west-2.amazonaws.com/metaquarium.art/audio/sprites/charlotteSprites.mp3";

export const DEFAULT_KTX2_TRANSCODER_LOCATION = "https://www.gstatic.com/basis-universal/versioned/2021-04-15-ba1c3e4/";
export const lambda3 = "https://rh2bsmk3kbyz7qi4qfd3imthwy0qztan.lambda-url.us-west-1.on.aws/";

export const openseaCollection = "https://opensea.io/collection/metaquarium";

const charlotteSprites = require("@assets/audio/charlotteSprites.json");
export const charlotteSpritesObject = charlotteSprites["sprite"];
export const ipfsGateways = {
	cloudflare: "https://cloudflare-ipfs.com/ipfs/",
	ipfs: "https://ipfs.io/ipfs/",
	pinata: "https://gateway.pinata.cloud/ipfs/",
	nftStorage: "https://nftstorage.link/ipfs/",
	filebase: "https://unfortunate-moccasin-blackbird.myfilebase.com/ipfs/",
};

export const ipfsUrl = ipfsGateways.filebase;

export const visualizerVideosMinimal = {
	src: videoS3Url + "/visualizers/1-86.mp4",
	poster: videoS3Url + "/visualizers/1-poster.jpg",
};

export const visualizerVideos = {
	src: videoS3Url + "/visualizers/1-86.mp4",
	poster: videoS3Url + "/visualizers/1-poster.jpg",
	srcSet: {
		"1x": {
			type: "video/mp4",
			src: videoS3Url + "/visualizers/1-86.mp4",
		},
		"1x-webm": {
			type: "video/webm",
			src: videoS3Url + "/visualizers/1-86.webm",
		},
		"2x": {
			type: "video/mp4",
			src: videoS3Url + "/visualizers/1-172.mp4",
		},
		"2x-webm": {
			type: "video/webm",
			src: videoS3Url + "/visualizers/1-172.webm",
		},
		"3x": {
			type: "video/mp4",
			src: videoS3Url + "/visualizers/1-258.mp4",
		},
		"3x-webm": {
			type: "video/webm",
			src: videoS3Url + "/visualizers/1-258.webm",
		},
	} as VideoSrcSet,
};

export const loaderVideos = {
	portrait: {
		poster: videoS3Url + "/loader/loader-5-merged-1080-1080-poster.jpg",
		width: 1080,
		height: 1920,
		currentTime: 0,
		p1080: {
			mp4: videoS3Url + "/loader/loader-5-portrait-1080.mp4",
			webm: videoS3Url + "/loader/loader-5-merged-1080-1080-vp9.webm",
			poster: videoS3Url + "/loader/loader-5-merged-1080-1080-poster.jpg",
		},
		p480: {
			mp4: videoS3Url + "/loader/loader-5-portrait-480.mp4",
			webm: videoS3Url + "/loader/loader-5-merged-480-480-vp9.webm",
			poster: videoS3Url + "/loader/loader-5-merged-480-480-poster.jpg",
		},
	},
	landscape: {
		poster: videoS3Url + "/loader/loader-5-merged-1280-1280-poster.jpg",
		width: 1920,
		height: 1080,
		currentTime: 0,
		p1080: {
			mp4: videoS3Url + "/loader/loader-5-landscape-1080.mp4",
			webm: videoS3Url + "/loader/loader-5-merged-1280-1280-vp9.webm",
			poster: videoS3Url + "/loader/loader-5-merged-1280-1280-poster.jpg",
		},
		p480: {
			mp4: videoS3Url + "/loader/loader-5-landscape-720.mp4",
			webm: videoS3Url + "/loader/loader-5-merged-1280-480-vp9.webm",
			poster: videoS3Url + "/loader/loader-5-merged-1280-480-poster.jpg",
		},
	},
};

export const threeLayers = { ENTIRE_SCENE: 0, BLOOM: 10, COMMERCIAL: 20, VOID: 30 };

export const bitmidi = {
	baseUrl: "https://bitmidi.com",
	random: "https://bitmidi.com/api/midi/random",
	all: "https://bitmidi.com/api/midi/all",
};

export const GIFs = [
	{
		filename: "oc1.gif",
		size: "cover",
	},
	{
		filename: "oc2.gif",
		size: "contain",
	},
	{
		filename: "oc3.gif",
		size: "cover",
	},
	{
		filename: "oc4.gif",
		size: "cover",
	},
	{
		filename: "oc5.gif",
		size: "cover",
	},
];
export const chromeApplicationId = "CA2D0C33";
export const chromeApplicationIdStyled = "5DF99FCE";
// export const chromeApplicationIdDefault = chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID;

export const metaMaskDeeplink = "https://metamask.app.link/dapp/metaquarium.xyz";

export const discordInvite = "https://discord.gg/wGRYe69nXq";
export const discordGMInvie = "https://discord.gg/NWj9JM6q";
export const gptUrl = "https://chatgpt.com/g/g-ZKFQh7Wg9-metaquarium";

export const defaultDevice: Device = {
	isDesktop: false,
	isMobile: false,
	isTablet: false,
	isElectron: false,
	isTabVisible: false,
	isStandalone: false,
	isPIP: false,
	isWebGPU: false,
	isWasd: false,
	hasMIDI: false,
	hasHeadphones: false,
	hasSpatialAudio: false,
	hasStick: false,
	hasVR: false,
	hasXR: false,
	hasAirplay: false,
	hasCast: false,
	hasTouch: false,
	hasKeyboard: false,
	hasMouse: false,
	hasDocumentPIP: false,
	hasWebGPU: false,
	orientation: "unknown",
	gpu: {
		tier: 1,
		type: "BENCHMARK",
		isMobile: false,
		fps: 15,
		device: "AMD K6-2 3DNOW! 450MHz",
	},
	deviceInfo: {
		browser: "Chrome",
		browser_version: "90.0.4430.93",
		os: "Mac OS",
		os_version: "10.15.7",
		device: "Mac",
		deviceType: "desktop",
		userAgent: "Chrome 90.0.4430.93",
		orientation: "unknown",
	},
};

export const knobChainDetents = [
	{
		label: "Solana",
		color: getSynthwaveColor(),
		active: true,
		angle: 0,
	},
	{
		label: "Dogecoin",
		color: getSynthwaveColor(),
		active: false,
		angle: 0,
	},
	{
		label: "Bitcoin",
		color: getSynthwaveColor(),
		active: false,
		angle: 0,
	},
	{
		label: "Polygon",
		color: getSynthwaveColor(),
		active: false,
		angle: 0,
	},
	{
		label: "Ethereum",
		color: getSynthwaveColor(),
		active: false,
		angle: 0,
	},
	{
		label: "Base",
		color: getSynthwaveColor(),
		active: false,
		angle: 0,
	},
	{
		label: "LongBeach",
		color: getSynthwaveColor(),
		active: false,
		angle: 0,
	},
	{
		label: "MontereyBay",
		color: getSynthwaveColor(),
		active: false,
		angle: 0,
	},
];
